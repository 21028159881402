@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  position: relative;
  padding-top: px-to-rem(460px);

  @media (min-width: $desktop-breakpoint) {
    padding-top: px-to-rem(56px);
  }

  &Mobile {
    position: absolute;
    max-width: px-to-rem(271px);
    height: auto;
    z-index: -1;
    top: 36px;
    left: 0;
    right: 0;
    margin: 0 auto;

    @media (min-width: $desktop-breakpoint) {
      right: px-to-rem(141px);
      top: px-to-rem(-360px);
      margin: initial;
      left: initial;
    }
  }

  &Paper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.downloadApp {
  color: #5856D6;
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  font-feature-settings: "liga" off, "clig" off;
}
