@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.reels {
  padding: px-to-rem(20px) 0 0;

  @media (min-width: $table-breakpoint) {
    padding: px-to-rem(30px) 0 0;
  }

  @media (min-width: $desktop-breakpoint) {
    padding: px-to-rem(155px) 0 0;
  }
}

.reel {
  width: px-to-rem(212px);
  height: px-to-rem(377px);
  flex-shrink: 0;
  border-radius: px-to-rem(16px);
  background: gray;
  margin: 0 px-to-rem(20px) 0 0;
}
