@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  border-radius: px-to-rem(15px);
  background: rgba(255, 255, 255, 0.80);
  backdrop-filter: blur(px-to-rem(12.25px));
  width: px-to-rem(225px);
  padding: px-to-rem(22.91px) px-to-rem(8px) px-to-rem(17px) px-to-rem(6px);
  margin: px-to-rem(31px) auto 0;
  display: flex;
  flex-direction: column;

  @media (min-width: $table-breakpoint) {
    padding: px-to-rem(60.26px) px-to-rem(53.05px) px-to-rem(55.52px) px-to-rem(53.04px);
    width: px-to-rem(368px);
  }

  @media (min-width: $desktop-breakpoint) {
    position: absolute;
    top: px-to-rem(220px);
    right: px-to-rem(150px);
  }

  &_avatar {
    height: px-to-rem(48px);
    width: px-to-rem(48px);
    margin: 0 auto px-to-rem(11px);

    @media (min-width: $table-breakpoint) {
      height: px-to-rem(46.174px);
      width: px-to-rem(46.174px);
      margin: 0 auto px-to-rem(16.58px);
    }
  }

  &_text_download {
    color: #5856D6;
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    font-feature-settings: 'liga' off, 'clig' off;

    @media (min-width: $table-breakpoint) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120.03%;
    }
  }

  &_text_container {
    display: flex;
    flex-direction: column;
    row-gap: px-to-rem(10px);

    @media (min-width: $table-breakpoint) {
      row-gap: px-to-rem(12.08px);
    }
  }

  &_text {
    align-items: center;
    display: flex;
    column-gap: px-to-rem(12px);
    font-size: px-to-rem(12px);
    line-height: normal;
    white-space: pre-line;

    strong {
      @media (min-width: $table-breakpoint) {
        font-size: px-to-rem(18px);
      }
    }

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(15.725px);
      font-style: normal;
      line-height: 120.03%;
      column-gap: px-to-rem(14.09px);
    }

    svg {
      height: px-to-rem(24px);
      width: px-to-rem(24px);
      flex-shrink: 0;

      @media (min-width: $table-breakpoint) {
        height: px-to-rem(29.791px);
        width: px-to-rem(29.791px);
      }
    }
  }
}

.modalContainer {
  position: fixed;
  background: rgba(0, 0, 0, 0.16);
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.modal {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: px-to-rem(20px);
  border-radius: px-to-rem(15px);
  background: #FFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: px-to-rem(48px) px-to-rem(19px) px-to-rem(31px) px-to-rem(20px);
  max-width: px-to-rem(288px);
  width: 100%;
  z-index: 9999999;

  @media (min-width: $desktop-breakpoint) {
    padding: px-to-rem(48px) px-to-rem(157px) px-to-rem(30px) px-to-rem(156px);
    max-width: px-to-rem(710px);
  }

  &_icons_container {
    display: flex;
    flex-direction: column;
    row-gap: px-to-rem(8.42px);

    @media (min-width: $desktop-breakpoint) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: px-to-rem(16px);
      row-gap: px-to-rem(15.87px);
    }

    & a, svg {
      width: px-to-rem(129.85px);

      @media (min-width: $desktop-breakpoint) {
        width: px-to-rem(158px);
        height: px-to-rem(52.566px);
      }
    }

    & a:nth-child(even) {
      @media (min-width: $desktop-breakpoint) {
        width: px-to-rem(178px);

        & svg {
          width: px-to-rem(178px);
        }
      }
    }
  }

  &_text {
    color: #1C1C1E;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(12px);
    font-style: normal;
    font-weight: 700;

    @media (min-width: $desktop-breakpoint) {
      font-size: px-to-rem(20px);
      line-height: px-to-rem(28px);
    }
  }

  &_btn {
    border-radius: px-to-rem(100px);
    background: #5856D6;
    box-shadow: 0 px-to-rem(4px) px-to-rem(12px) 0 rgba(88, 86, 214, 0.24);
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(14px);
    font-style: normal;
    font-weight: 500;
    width: px-to-rem(152px);
    line-height: px-to-rem(26px);
    display: none;

    @media (min-width: $table-breakpoint) {
      display: block;
    }

    @media (min-width: $desktop-breakpoint) {
      display: block;
    }
  }
}
