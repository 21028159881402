@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  max-width: px-to-rem(288px);
  height: px-to-rem(320px);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: px-to-rem(15px);
  background: url("../../shared/assets/images/lotus_popup.jpg") no-repeat center center;
  background-size: cover;
  z-index: 9999999;

  @media (min-width: $table-breakpoint) {
    max-width: px-to-rem(636px);
    height: px-to-rem(685px);
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: px-to-rem(636px);
    height: px-to-rem(685px);
    border-radius: px-to-rem(39px);
  }

  &_popup {
    box-shadow: 0 px-to-rem(-3px) px-to-rem(25.5px) 0 rgba(0, 0, 0, 0.25);
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: px-to-rem(9px);
    border-radius: px-to-rem(33px) px-to-rem(33px) 0 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: px-to-rem(93px);

    @media (min-width: $table-breakpoint) {
      height: px-to-rem(153px);
      row-gap: px-to-rem(15px);
    }

    @media (min-width: $desktop-breakpoint) {
      height: px-to-rem(153px);
      row-gap: px-to-rem(15px);
    }
  }

  &_close_icon_container {
    cursor: pointer;
    border-radius: px-to-rem(12px);
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(px-to-rem(14px));
    padding: px-to-rem(15px) px-to-rem(13px) px-to-rem(10px) px-to-rem(15px);
    position: absolute;
    top: px-to-rem(13px);
    right: px-to-rem(13px);
    width: px-to-rem(44px);
    height: px-to-rem(44px);

    @media (min-width: $table-breakpoint) {
      top: px-to-rem(30px);
      right: px-to-rem(30px);
      width: px-to-rem(53px);
      height: px-to-rem(53px);
    }

    @media (min-width: $desktop-breakpoint) {
      top: px-to-rem(30px);
      right: px-to-rem(30px);
      width: px-to-rem(53px);
      height: px-to-rem(53px);
    }
  }

  &_title {
    color: #1C1C1E;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(12px);
    font-style: normal;
    font-weight: 700;
    line-height: px-to-rem(28px);
    text-align: center;

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(20px);
    }

    @media (min-width: $desktop-breakpoint) {
      font-size: px-to-rem(20px);
    }
  }

  &_action_container {
    display: flex;
    gap: px-to-rem(14px);
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  &_register {
    border-radius: px-to-rem(100px);
    background: #5856D6;
    box-shadow: 0 px-to-rem(4px) px-to-rem(12px) 0 rgba(88, 86, 214, 0.24);
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(14px);
    font-style: normal;
    font-weight: 500;
    line-height: px-to-rem(26px);
    padding: px-to-rem(7px) px-to-rem(31px);
    height: px-to-rem(40px);
    display: inline-flex;
  }
}
