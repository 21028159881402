@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.appleStore, .ruStore {
  display: flex;
  align-items: center;
  width: px-to-rem(129.85px);

  @media (min-width: $table-breakpoint) {
    width: px-to-rem(158px);
    height: px-to-rem(52.566px);
  }
}

.googleStore, .nashStore {
  display: flex;
  align-items: center;
  width: px-to-rem(129.85px);

  @media (min-width: $table-breakpoint) {
    width: px-to-rem(178px);

    & svg {
      width: px-to-rem(178px);
    }
  }
}
