@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.customArrows {
  display: none;
  gap: px-to-rem(40px);
  padding: px-to-rem(16px) 0 0;
  justify-content: end;

  @media (min-width: $table-breakpoint) {
    display: flex;
  }

  @media (min-width: $desktop-breakpoint) {
    display: flex;
  }

  &_prev {
    cursor: pointer;
  }

  &_next {
    cursor: pointer;
  }
}

.customDots {
  display: flex;
  gap: px-to-rem(3px);
  justify-content: center;
  margin-top: px-to-rem(14px);
  position: relative;
  z-index: 99999;

  @media (min-width: $table-breakpoint) {
    margin-top: px-to-rem(-19px);
  }

  @media (min-width: $desktop-breakpoint) {
    margin-top: px-to-rem(-19px);
  }

  &_dot {
    border-radius: 3px;
    width: px-to-rem(5px);
    background: #717172;
    height: px-to-rem(2px);
  }

  &_active_dot {
    width: px-to-rem(48px);
  }
}
