@import 'src/shared/lib/px-to-rem';

.show_more {
  background: #000;
  box-shadow: none;
  margin: px-to-rem(39px) auto;
  color: #FFF;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  width: px-to-rem(127px);
}

.spinner {
  display: flex;
  margin: 0 auto;
}
