@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.winner {
  position: absolute;
  right: 0;
  max-width: px-to-rem(640px);
  height: auto;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  display: none;

  @media (min-width: $table-breakpoint) {
    display: none;
  }

  @media (min-width: $desktop-breakpoint) {
    display: initial;
  }

  @media (min-width: 1680px) {
    max-width: px-to-rem(840px);
  }
}

.winner2 {
  position: absolute;
  right: px-to-rem(-52px);
  height: auto;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  top: px-to-rem(89px);
  max-width: px-to-rem(342px);
  transform: scale(2);

  @media (min-width: $table-breakpoint) {
    display: none;
  }

  @media (min-width: $desktop-breakpoint) {
    display: none;
  }
}

.imgContainer {
  position: relative;
  overflow-x: clip;
}

.tabletWinner {
  position: absolute;
  right: 0;
  max-width: px-to-rem(486px);
  height: auto;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  display: none;

  @media (min-width: $table-breakpoint) {
    display: initial;
  }

  @media (min-width: $desktop-breakpoint) {
    display: none;
  }
}

.title {
  color: var(--text-black);
  font-weight: 700;
  font-size: px-to-rem(64px);
  line-height: px-to-rem(72px);
  letter-spacing: px-to-rem(0.716112px);

  @media (min-width: $table-breakpoint) {
    font-size: px-to-rem(120px);
    line-height: px-to-rem(128px);
    text-align: initial;
  }
}

.socialNetwork {
  font-size: px-to-rem(12px);
  line-height: px-to-rem(14px);
  color: var(--text-black);
  padding: px-to-rem(30px) 0 0;

  @media (min-width: $table-breakpoint) {
    font-size: px-to-rem(20px);
    line-height: px-to-rem(32px);
  }

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(20px);
    line-height: px-to-rem(32px);
  }
}

.description {
  max-width: px-to-rem(171px) !important;
  text-align: left !important;
  font-size: px-to-rem(12px) !important;
  line-height: px-to-rem(14px) !important;
  padding: 0 !important;

  @media (min-width: $table-breakpoint) {
    max-width: px-to-rem(379px) !important;
    font-size: px-to-rem(20px) !important;
    line-height: px-to-rem(32px) !important;
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: px-to-rem(379px) !important;
    font-size: px-to-rem(20px) !important;
    line-height: px-to-rem(32px) !important;
  }
}

.description,
.moto {
  color: var(--text-black);
  padding-top: px-to-rem(16px);
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  text-align: center;
  white-space: pre-line;

  @media (min-width: $table-breakpoint) {
    text-align: initial;
    padding-top: px-to-rem(24px);
    font-size: px-to-rem(20px);
    line-height: px-to-rem(32px);
    max-width: px-to-rem(371px);
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: px-to-rem(533px);
  }
}

.moto {
  text-align: left;
}

.rag {
  text-align: left;
  font-size: px-to-rem(16px) !important;
  line-height: px-to-rem(24px) !important;
  padding-top: px-to-rem(16px) !important;
  max-width: initial !important;

  @media (min-width: $table-breakpoint) {
    max-width: px-to-rem(578px) !important;
  }

  @media (min-width: $table-breakpoint) {
    max-width: px-to-rem(528px) !important;
  }
}

.moto {
  font-weight: 700;
}

.divider {
  height: px-to-rem(1px);
  background: var(--devider-clr);
  border-radius: 12px;
}

.ourPurpose {
  color: var(--text-black);
  padding-top: px-to-rem(16px);
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);

  @media (min-width: $table-breakpoint) {
    font-size: px-to-rem(16px);
    line-height: px-to-rem(24px);
    padding-top: px-to-rem(20px);
    max-width: px-to-rem(528px);
  }

  @media (min-width: $desktop-breakpoint) {
    padding-top: px-to-rem(24px);
  }
}

.subTitle {
  color: var(--text-black);
  font-weight: 700;
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);
  padding-top: px-to-rem(28px);
  white-space: pre-wrap;

  @media (min-width: $table-breakpoint) {
    font-size: px-to-rem(28px);
    line-height: px-to-rem(36px);
    padding-top: px-to-rem(32px);
  }

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(40px);
    line-height: px-to-rem(48px);
    padding-top: px-to-rem(65px);
  }
}

.downloadAppLinksUp {
  column-gap: px-to-rem(14px);
  padding-top: px-to-rem(24px);
  padding-bottom: px-to-rem(40px);
  row-gap: px-to-rem(12.98px);
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  display: none;

  a {
    width: px-to-rem(145px);
  }

  a:first-child {
    width: px-to-rem(129px);
  }

  a:nth-child(3) {
    width: px-to-rem(129px);
  }

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(32px);
    padding-bottom: 0;
    margin: initial;
    flex-wrap: initial;
    max-width: px-to-rem(680px);
    justify-content: flex-start;
    display: flex;
  }

  @media (min-width: $desktop-breakpoint) {
    padding-top: px-to-rem(30px);
    padding-bottom: px-to-rem(84px);
    flex-wrap: wrap;
    width: initial;
    max-width: px-to-rem(372px);
    justify-content: initial;
    display: flex;

    a {
      width: initial;
    }

    a:first-child {
      width: initial;
    }

    a:nth-child(3) {
      width: initial;
    }
  }
}

.downloadAppLinks {
  padding-top: px-to-rem(48px);
  padding-bottom: px-to-rem(80px);

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(80px);
  }

  @media (min-width: $desktop-breakpoint) {
    padding-top: px-to-rem(26.48px);
  }

  &Title {
    color: var(--text-black);
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    line-height: 26px;

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(24px);
      line-height: px-to-rem(32px);
    }

    @media (min-width: $desktop-breakpoint) {
      font-size: px-to-rem(28px);
      line-height: px-to-rem(36px);
    }
  }

  &Icons {
    margin: 0 auto;
    padding-top: px-to-rem(32px);
    justify-content: center;

    a {
      width: px-to-rem(145px);
    }

    a:first-child {
      width: px-to-rem(129px);
    }

    a:nth-child(3) {
      width: px-to-rem(129px);
    }

    @media (min-width: $desktop-breakpoint) {
      a {
        width: initial;
      }

      a:first-child {
        width: initial;
      }

      a:nth-child(3) {
        width: initial;
      }
    }
  }
}

.timeIsMoneyLogo {
  display: block;
  margin: 0 auto;
  padding: px-to-rem(10px) 0 0;
  width: px-to-rem(49px);
  height: px-to-rem(49px);

  @media (min-width: $table-breakpoint) {
    width: px-to-rem(64px);
    height: px-to-rem(64px);
    margin: initial;
  }

  @media (min-width: $desktop-breakpoint) {
    margin: initial;
    width: px-to-rem(64px);
    height: px-to-rem(64px);
  }
}

.newLogo {
  display: none;
  margin: 0 auto;

  @media (min-width: $desktop-breakpoint) {
    display: flex;
  }
}

.newLogoForMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: px-to-rem(40px) 0 px-to-rem(16px);
  border-bottom: 1px solid #E7E7F1;

  h2 {
    color: #1C1C1E;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(64px);
    font-style: normal;
    font-weight: 700;
    line-height: px-to-rem(72px);
    letter-spacing: px-to-rem(0.716px);
    padding: 0 0 px-to-rem(4px);
  }

  p {
    padding: px-to-rem(7px) 0 0;
    color: #1C1C1E;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(16px);
    font-style: normal;
    font-weight: 400;
    line-height: px-to-rem(24px);
  }

  @media (min-width: $desktop-breakpoint) {
    display: none;
  }
}
