@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.partners {
  padding: px-to-rem(48px) 0 0;

  @media (min-width: $desktop-breakpoint) {
    padding: px-to-rem(120px) 0 0;
  }

  &_title {
    color: #1C1C1E;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(18px);
    font-style: normal;
    font-weight: 700;
    line-height: px-to-rem(26px);

    @media (min-width: $desktop-breakpoint) {
      font-size: px-to-rem(40px);
      line-height: px-to-rem(48px);
      text-align: left;
    }
  }

  &_list {
    display: grid;
    column-gap: px-to-rem(28px);
    row-gap: px-to-rem(15px);
    grid-template-columns: 1fr 1fr;
    padding: px-to-rem(24px) 0 0;

    @media (min-width: $desktop-breakpoint) {
      column-gap: px-to-rem(24px);
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: px-to-rem(65px) 0 0;
    }
  }

  &_partner {
    height: px-to-rem(123px);
    border-radius: px-to-rem(12px);
    background: #D9D9D9;
    object-fit: cover;
    width: 100%;

    @media (min-width: $desktop-breakpoint) {
      width: px-to-rem(252px);
      height: px-to-rem(209px);
      flex-shrink: 0;
      border-radius: px-to-rem(24px);
    }
  }

  &_partner_title {
    color: #000;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: px-to-rem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: px-to-rem(22px);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    padding: px-to-rem(10px) 0 0;

    @media (min-width: $desktop-breakpoint) {
      font-size: px-to-rem(18px);
      line-height: px-to-rem(26px); /* 144.444% */
      padding: px-to-rem(15px) 0 0;
    }
  }
}
